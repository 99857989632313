import React from 'react';
import CategoryItem from "../components/CategoryItem";
import Header from "../components/Header";
import FilterItem from "../components/FilterItem";
import styled from "styled-components";
import bgRudder from "../assets/barrel.svg";
import bgBeer from "../assets/beer.svg";
import flagEN from "../assets/flag_uk.png";
import flagSL from "../assets/flag_slo.png";
import Section from "../components/Section";
import Spinner from "../components/Spinner";
import SimpleCard from '../components/SimpleCard';
import { inject, observer } from "mobx-react";
import HomeStore from "../stores/Home";
import Category from "../models/Category";
import Item from "../models/Item";
import { CellMeasurerCache } from "react-virtualized";
import Search from "./Search";
import { autorun, observable } from "mobx";
import TranslationStore, {
    T_HOME_BEST_SELLING, T_HOME_ERROR,
    T_HOME_FILTER,
    T_HOME_MY_TABLE
} from "../stores/translation";
import ApplicationStore, { Languages } from "../stores/Application";
import stores from "../stores";
import HorizontalScroll from "../components/HorizontalScroll";
import cocktail from "../assets/cocktail.svg";
import Table from "./Table";
import { StateError } from "../components/States";
import AnalyticsStore from '../stores/Analytics';
import { getFlatCategories } from '../helpers/categories';
import { CategoriesTree } from '../types/categories';

interface Props {}

interface StoreProps extends Props {
    translation: TranslationStore,
    home: HomeStore,
    application: ApplicationStore
    analytics: AnalyticsStore
}

@inject(stores.translation, stores.home, stores.application, stores.analytics)
@observer
class Home extends React.Component<Props> {
    cache: CellMeasurerCache;

    @observable
    showSearch = false;

    @observable
    showTable = false;

    componentDidMount() {
        this.init();

        autorun(() => {
            document.body.style.overflow = (this.showTable || this.showSearch) ? "hidden" : "visible";
        });
    }

    constructor(props: any) {
        super(props);

        this.cache = new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 100
        });
    }

    init = () => {
        this.store.home.init();
    }

    get store() {
        return this.props as StoreProps;
    }

    renderTopLevelCategories() {
        const categories = this.store.home.topLevelCategories;

        return <HorizontalScroll
            height={"50px"}
            data={categories.map((category: Category) => (
                <CategoryItem
                    key={category.id!}
                    title_en={category.title_en}
                    title_sl={category.title_sl}
                    isSelected={this.store?.home.selectedCategory === category.id}
                    onClick={() => {
                        this.store.home.setSelectedCategory(category.id)
                        this.store.home.setSelectedFilter(-1)
                        this.store.analytics.trackSelectedCategory(category)
                    }}
                />
            ))}

        />;
    }

    // TODO: Join renderComplexFilter with renderComplexFilterOptions
    renderComplexFilter(categoriesTree: CategoriesTree[]) {
        return categoriesTree.map(node => (
            <ComplexFilterGroup key={node.category?.id}>
                <FilterItem
                    key={node.category!.id!}
                    title_en={node.category!.title_en}
                    title_sl={node.category!.title_sl}
                    onClick={() => {
                        this.store.home.setSelectedFilter(node.category?.id)
                    }}
                    isSelected={this.store.home.selectedFilter === node.category?.id}
                />
                {this.renderComplexFilterOptions(node.category!.id!, node.children)}
            </ComplexFilterGroup>
        ))
    }

    renderComplexFilterOptions(key: number, categoriesTree: CategoriesTree[]) {
        return <ComplexFilterGroup key={`sub-${key}`}>
            {categoriesTree.map(node => (
                <FilterItem
                    key={node.category!.id!}
                    title_en={node.category!.title_en}
                    title_sl={node.category!.title_sl}
                    onClick={() => {
                        this.store.home.setSelectedFilter(node.category?.id)
                    }}
                    isSelected={this.store.home.selectedFilter === node.category!.id}
                />
            ))}
        </ComplexFilterGroup>
    }

    requiresSimpleFilter = (categoryTree: CategoriesTree): boolean => {
        if (categoryTree.children.length) {
            return categoryTree.children.every(node => !node.children.length)
        }

        return true;
    }

    renderSimpleFilter = (subcategories: Category[]) => {
        return <HorizontalScroll
            height={"35px"}
            data={
                subcategories.map((category: Category) => (
                    <FilterItem
                        key={category.id!}
                        title_en={category.title_en}
                        title_sl={category.title_sl}
                        onClick={() => {
                            this.store.home.setSelectedFilter(category.id)
                            this.store.analytics.trackSelectedCategory(category)
                        }}
                        isSelected={this.store.home.selectedFilter === category.id}
                    />
                ))
            }
        />
    }

    renderFilterOptions(categoryTree: CategoriesTree) {
        if (this.requiresSimpleFilter(categoryTree)) {
            const subcategories = categoryTree.children.map(node => node.category) as Category[]
            return this.renderSimpleFilter(subcategories)
        }

        return this.renderComplexFilter(categoryTree.children);
    }

    renderFilter() {
        const categoriesTree = this.store.home.getCategoriesTree(this.store.home.selectedCategory);

        let all: Category = new Category();
        all.title_en = "All";
        all.title_sl = "Vse";
        all.id = -1;

        if (categoriesTree.children.length) {
            categoriesTree.children = [{
                category: all,
                children: [],
            }, ...categoriesTree.children]
        }

        if (!categoriesTree.children.length) {
            return null;
        }

        return <React.Fragment>
            <Section title={this.store?.translation?.get(T_HOME_FILTER)} icon="filter" padding="0 1rem" />
            {this.renderFilterOptions(categoriesTree)}
        </React.Fragment>;
    }

    handleItemSelection = (id: number) => {
        this.store.home.toggleProductSelection(id);
    }

    isProductSelected = (id: number) => {
        return this.store.home.isProductSelected(id);
    }

    renderCategoryDescription = () => {
        const category = this.store.home.topLevelCategories.find((c: Category) => c.id === this.store.home.selectedCategory);
        return this.renderCategoryDescriptionItem(category)
    }

    renderSubcategoryDescription = (id: number) => {
        const category = this.store.home.categoriesState.data.find((c: Category) => c.id === id);
        return this.renderCategoryDescriptionItem(category)
    }

    renderCategoryDescriptionItem(category?: Category) {
        if (!category) {
            return null
        }

        const isSlovenianLanguage = this.store.application.language === Languages.slovenian;
        const description = isSlovenianLanguage ? category.description_sl : category.description_en;

        if (!description?.length) {
            return null;
        }

        return <CategoryDescription>{description}</CategoryDescription>
    }


    renderPromoted = () => {
        const categoriesTree = this.store.home.getCategoriesTree(this.store.home.selectedCategory);
        let categories = getFlatCategories([categoriesTree]).map(category => category.id!);

        const promotedItems: Array<Item> = this.store.home.itemsState.data.filter((c: Item) => categories.indexOf(c.category!) !== -1 && c.promoted);
        if (!promotedItems.length) {
            return null
        }

        return (
            <div>
                <Section title={this.store?.translation?.get(T_HOME_BEST_SELLING)} />
                {this.renderItemsList(promotedItems)}
            </div>
        )
    }

    renderCategoryWithItems = (categoriesTree: CategoriesTree[]) => {
        if (!categoriesTree.length) {
            return null;
        }

        return categoriesTree.map(node => {
            const isLeafNode = !node.children.length;
            const items = isLeafNode ? this.store.home.itemsState.data.filter((i: Item) => i.category === node.category!.id) : []
            const isSlovenian = this.store.application.language === Languages.slovenian;
            const category = node.category
            if (!category) {
                return null
            }

            const categoryTitle = isSlovenian ? category?.title_sl : category?.title_en

            return (
                <div key={category.id}>
                    {isLeafNode && <Section title={categoryTitle} />}
                    {isLeafNode && this.renderSubcategoryDescription(category.id ?? -1)}
                    {this.renderCategoryWithItems(node.children)}
                    {isLeafNode && this.renderItemsList(items)}
                </div>
            )
        })
    }

    renderItems() {
        const selectedID = this.store.home.selectedFilter;
        const selectedCategory = this.store?.home.selectedCategory;
        const isAnyFilterSelected = selectedID === -1
        const categoriesTree = this.store.home.getCategoriesTree(isAnyFilterSelected ? selectedCategory : selectedID);

        const hasSubcategories = categoriesTree.children.length;

        if (isAnyFilterSelected && !hasSubcategories) {
            const items = this.store.home.itemsState.data.filter((i: Item) => i.category === selectedCategory);
            return this.renderItemsList(items)
        }

        if (isAnyFilterSelected || hasSubcategories) {
            return this.renderCategoryWithItems(categoriesTree.children)
        }

        const items = this.store.home.itemsState.data.filter((i: Item) => i.category === selectedID);
        const isSlovenian = this.store.application.language === Languages.slovenian;

        return (
            <div>
                <Section title={isSlovenian ? categoriesTree.category?.title_sl : categoriesTree.category?.title_en} />
                {this.renderSubcategoryDescription(categoriesTree.category?.id ?? -1)}
                {this.renderItemsList(items)}
            </div>
        )
    }

    renderItemsList = (items: Item[]) => {
        return items.map((item: Item) =>
            <SimpleCard
                key={item.id}
                id={item.id}
                title_en={item.title_en}
                title_sl={item.title_sl}
                subtitle_en={item.subtitle_en}
                subtitle_sl={item.subtitle_sl}
                image={item.image}
                promoted={item.promoted}
                price={item.price!.toFixed(2)}
                isSelected={this.isProductSelected(item.id!)}
                onClick={() => this.handleItemSelection(item.id!)}
            />
        )
    }

    render() {
        return (
            <div>
                {
                    this.showSearch && <FixedContainer>
                        <Search onClose={() => (this.showSearch = false)} />
                    </FixedContainer>
                }
                {
                    this.showTable && <FixedContainer>
                        <Table onClose={() => (this.showTable = false)} />
                    </FixedContainer>
                }
                <div className="main">
                    <Header onSearch={() => {
                        this.showSearch = true;
                    }} />
                    {this.store.home.categoriesState.isError && <StateError title={this.store?.translation?.get(T_HOME_ERROR)} onRetry={() => this.store.home.init()} />}
                    {this.store.home.categoriesState.isLoading && <Spinner />}
                    {
                        !this.store.home.categoriesState.isLoading && !this.store.home.categoriesState.isError &&
                        <Wrapper>
                            <BackgroundRudder><img alt="" src={bgRudder} /></BackgroundRudder>
                            <CategoriesWrapper>
                                {this.renderTopLevelCategories()}
                                {this.renderCategoryDescription()}
                            </CategoriesWrapper>
                            <CategoriesPromotedWrapper>
                                {this.renderPromoted()}
                            </CategoriesPromotedWrapper>
                            <FilterWrapper>
                                {this.renderFilter()}
                            </FilterWrapper>
                            <ItemsWrapper>
                                {this.renderItems()}
                            </ItemsWrapper>
                        </Wrapper>
                    }
                    <TableWrapper>
                        <MyTable
                            onClick={() => {
                                this.showTable = true;
                            }}
                        >
                            <img width={20} src={cocktail} alt="" />
                            <span>{this.store?.translation?.get(T_HOME_MY_TABLE)} ({this.store.home?.total?.toFixed(2)} €)</span>
                        </MyTable>
                    </TableWrapper>
                    {<LanguageWrapper>
                        <Language
                            onClick={() => {
                                this.store.application.changeLanguage(Languages.english)
                            }}>
                            <LanguageFlag src={flagEN} />
                        </Language>
                        <Language
                            onClick={() => {
                                this.store.application.changeLanguage(Languages.slovenian)
                            }}>
                            <LanguageFlag src={flagSL} />
                        </Language>
                    </LanguageWrapper>}
                </div>
                <Version>v{process.env.REACT_APP_VERSION}</Version>
                <Vessel src={bgBeer} />
            </div>
        );
    }
}

export default Home;

const Version = styled.small`
    position: fixed;
    color: rgba(255,255,255,0.6);
    right: 1rem;
    bottom: 0;
    font-size: 10px;
`

const FixedContainer = styled.div`
  background: ${p => p.theme.app.background};
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  height: 100vh;
  padding: 1rem;
  box-sizing: border-box;
  overflow-y: auto;
`;

const Wrapper = styled.div`
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
  margin-top: -50px;
  background: ${p => p.theme.app.background};
  padding-bottom: 100px;
`;

const ComplexFilterGroup = styled.ul`
    margin: 0;
    padding: 0;

    & > ul {
        margin-left: 20px !important;
    }
`

const ItemsWrapper = styled.div`
  padding: 0 1rem;
  height: 100%;
`;

const FilterWrapper = styled.div`
  margin-bottom: 1rem;
`;

const CategoriesWrapper = styled.div`
  padding: 1rem 0;
`;

const CategoriesPromotedWrapper = styled.div`
  padding: 0 1rem;
`;

const CategoryDescription = styled.div`
  margin: 1rem 1rem;
  color: ${p => p.theme.app.primary};
  text-align: center;
  line-height: 1.5;
  font-style: italic;
  font-size: 14px;
`;

const BackgroundRudder = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-90%);
  width: 208px;
  height: 102px;
  user-select: none;
  pointer-events: none;
  opacity: 0.05;
`;

const LanguageWrapper = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  display: flex;
`;

const Language = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 50px;
  height: 50px;
  margin-left: 1rem;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

const LanguageFlag = styled.img`
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
`;

const TableWrapper = styled.div`
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  display: flex;
  z-index: 99;
`;

const MyTable = styled.div`
    background: ${p => p.theme.app.accent};
    color: ${p => p.theme.app.primary};
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 0 1rem;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    
    span {
      padding-left: 10px;
    }
`;

const Vessel = styled.img`
    position: fixed;
    bottom: 0;
    z-index: 9;
    left: 0;
    pointer-events: none;
    opacity: 0.05;
    width: 150px;
    transform: rotateZ(20deg);
`;
